import React, { useEffect, useState } from "react"
import { SectionTitle } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import JobContent from "../components/JobContent"
import SearchJob from "../components/SearchJob"
import { Seo } from "../components/seo"
import useJobs from "../hooks/useJobs"
import { graphql } from 'gatsby'
const JobsTypesTemplate = props => {
  const dataJobs = useJobs("Japanese")
  const dataJobTypes = props.data.allStrapiType.nodes
  const [dataGroupType, setDataGroupType] = useState([])
  useEffect(() => {
    dataJobs.forEach(element => {
      for (let j = 0; j < element.types.length; j++) {
        if (dataJobTypes[0].name === element.types[j].name) {
          setDataGroupType(prevState => [...prevState, element])
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (

      <section className="container jobs-listing">
        <div className="row">
          <div className="col-lg-9">
            <div className="jobs-listing__search">
              <SectionTitle>メキシコ求人・就職・転職情報</SectionTitle>
              <hr className="u-line-bottom" />
              <div
                className="jobs-listing__search--box"
                style={{ padding: "0" }}
              >
                <SearchJob lang={"ja"} />
              </div>
            </div>
            <div
              className="jobs-listing__recommended-jobs"
              style={{ padding: "0" }}
            ></div>
            <div className="jobs-listing__job-info">
              <SectionTitle>
                {dataJobTypes[0].name} : {dataGroupType.length} 件
              </SectionTitle>
              <hr className="u-line-bottom" />
              {dataGroupType.map((jobs, i) => (
                <>
                  {jobs.types.map(types => (
                    <>
                      {dataJobTypes[0].name === types.name && (
                        <React.Fragment key={"jobContent_" + i}>
                          <JobContent jobs={jobs} lang={"ja"} />
                        </React.Fragment>
                      )}
                    </>
                  ))}
                </>
              ))}
            </div>
          </div>
          <WrapperRight />
        </div>
      </section>
    
  )
}

export default JobsTypesTemplate


export const Head = ({ data }) => {
  
  const type = data?.allStrapiType?.nodes[0]?.name
  
  return (
    <Seo
      title={`${type}`}
      url={`https://www.919mexico.com/jobs-types/${type}`}
    />
  )
}


export const query = graphql`
  query jobType($slug: String!) {
    allStrapiType(filter: { name: { eq: $slug } }) {
      nodes {
        name
      }
    }
  }
`
